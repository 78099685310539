import { useEffect, useState } from "react";
import { useAPI } from '../utils/hooks/useAPI'; // Asegúrate de importar tu hook
import { IComment } from "../common/interfaces/responses/comments";
import { IDownload } from "../common/interfaces/responses/downloads";
import { useParams } from "react-router-dom";


const ConsultView = () => {
  const { id } = useParams(); // Obtiene el id de la URL
  const [seeComments, setSeeComments] = useState(true);
  const {
    apiMethods: { Get: getComments, response: commentsResponse },
  } = useAPI<IComment[]>({
    endpoint: `/consultations/${id}/comments`,
    isPrivate: true,
  });
  const {
    apiMethods: { Get: getDownloads, response: downloadsResponse },
  } = useAPI<IDownload[]>({
    endpoint: `/consultations/${id}/downloads`,
    isPrivate: true,
  });


  /////////////       Actions

  const handleCommentsTrue = () => {
    setSeeComments(true);
  };
  const handleCommentsFalse = () => {
    setSeeComments(false);
  };

  useEffect(() => {


    ////////////        fetch

    getComments("");
    getDownloads("");
  }, [id]);

  return (
    <>

      <div className="container-fluid d-flex">
        <div
          onClick={handleCommentsTrue}
          className={`w-50 text-center btn btn-no-rounded ${seeComments ? 'btn-success' : 'btn-outline-secondary'} my-5`}
        >
          See Comments
        </div>
        <div
          onClick={handleCommentsFalse}
          className={`w-50 text-center btn btn-no-rounded ${!seeComments ? 'btn-success' : 'btn-outline-secondary'} my-5`}
        >
          See Downloads
        </div>
      </div>

      {seeComments ? (
        <div style={{ maxHeight: "550px", overflowY: "auto" }}>
          <h2 className="text-center mb-3">Comentarios para la consulta {id}</h2>
          <table className="table table-bordered">
            <thead>
              <tr className="text-center">
                <th>Nombre</th>
                <th>Apellido</th>
                <th>Correo</th>
                <th>Comentario</th>
                <th className="col-2 text-center">Fecha</th>
              </tr>
            </thead>
            <tbody>
              {commentsResponse && commentsResponse?.data.map((comment, index) => (
                <tr key={`${comment.id || index}`}>
                  <td>{comment.name}</td>
                  <td>{comment.surname}</td>
                  <td>{comment.email}</td>
                  <td>{comment.comment}</td>
                  <td>{comment.created}</td>
                </tr>
              ))}
            </tbody>
          </table>

        </div>
      ) : (
        <div style={{ maxHeight: "550px", overflowY: "auto" }}>
          <h2 className="text-center mb-3">Descargas para la consulta {id}</h2>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Apellido</th>
                <th>Correo</th>
                <th>Fecha</th>
              </tr>
            </thead>
            <tbody>
              {downloadsResponse?.data.map((download, index) => (
                <tr key={`${download.id || index}`}>
                  <td>{download.name}</td>
                  <td>{download.surname}</td>
                  <td>{download.email}</td>
                  <td>{download.created}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default ConsultView;

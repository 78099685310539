import React from 'react';
import '../styles/ScreenLoading.css';

const ScreenLoading: React.FC = () => {
  return (
    <div className="loader-container">
      <div className="loader-spinner"></div>
    </div>
  );
};

export default ScreenLoading;
import { SubmitHandler } from "react-hook-form";
import { useAPI } from "../utils/hooks/useAPI";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../styles/home.css";
import "animate.css";
import { IRegistryRequest } from "../common/interfaces/requests/registry.request";
import ScreenLoading from "../components/ScreenLoading";
import { ICommentRequest } from "../common/interfaces/requests/comment.request";
import { registrySchema } from "../validations/registry.validation";
import ErrorAlert from "../components/ErrorAlert";
import { commentSchema } from "../validations/comment.validation";
import NotFound from "./NotFound";
import { useParams } from "react-router-dom";
import { IGetConsultationResponse } from "../common/interfaces/responses/get-consultation.response";

const PublicConsultation = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [exitAnimation, setExitAnimation] = useState("");
  //Actions
  const {
    apiMethods: { Get, response, isLoadingRequest },
  } = useAPI<IGetConsultationResponse>({
    endpoint: `/consultations/${id}`,
    isPrivate: false,
  });

  const {
    apiMethods: { Post: postRegistry, response: responseRegistry },
    formMethods: {
      handleSubmit: handleSubmitRegistry,
      formState: { errors: registryErrors },
      register: registerRegistry,
    },
  } = useAPI<any, IRegistryRequest>({
    endpoint: `/consultations/${id}/new-registry`,
    isPrivate: false,
    schema: registrySchema(t),
  });

  const {
    apiMethods: { Post: comment },
    formMethods: {
      handleSubmit: handleSubmitComment,
      formState: { errors: commentErrors },
      register: registerComment,
    }
  } = useAPI<any, ICommentRequest>({
    endpoint: `/consultations/${id}/comments`,
    isPrivate: false,
    schema: commentSchema(t),
  });

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = (animationType: string) => {
    setExitAnimation(animationType);
    setTimeout(() => {
      setShowModal(false);
      setExitAnimation("");
    }, 1000);
  };

  const onDownload: SubmitHandler<IRegistryRequest> = (data) => {
    postRegistry(data);

  };

  useEffect(() => {
    Get("");
  }, []);

  useEffect(() => {
    if (responseRegistry && response) {
      window.open(
        `${import.meta.env.VITE_BUCKET_URL}${i18n.language === "en"
          ? response.data?.documentNameEnglish
          : response.data?.documentNameSpanish
        }`
      );
    }
  }, [responseRegistry]);

  const onSubmitComment: SubmitHandler<ICommentRequest> = async (data) => {
    const comentario = {
      email: data.email,
      comment: data.comment,
    };
    comment(comentario);
    closeModal("animate__backOutRight");
  };

  if (isLoadingRequest) {
    return <ScreenLoading />;
  } else if (!response) {
    return <NotFound />
  } else
    return (
      <>
        <div className="container-fluid min-vh-80">
          <div className="row h-100 my-5">
            {/* This text depends on the api so it will be translated, so this text has been hard coded* /}
          {/* The translation depends on the flags button, so the carry on the correct translation, so it depends on the Header component. */}

            {response ? (
              <>
                <div className="col-8  p-4">
                  <div
                    className="mt-2 text-justify"
                    dangerouslySetInnerHTML={{
                      __html: i18n.language === 'es' ? response.data.descriptionSpanish : response.data.descriptionEnglish,
                    }} //acá se logra traer el html creado previamente
                  ></div>
                </div>
              </>
            ) : (
              <div className="col-8 p-4">
                <h2 className="font-bold">{t("consultationForm.title")}</h2>
              </div>
            )}

            <div className="col-4 ">

              <form
                className="w-75 mx-auto"
                onSubmit={handleSubmitRegistry(onDownload)}
              >
                <h2 className="text-center"><strong>{t("consultationForm.title")}</strong></h2>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    {t("consultationForm.name")}
                  </label>
                  <input
                    type="text"
                    className={`form-control ${registryErrors.name ? "is-invalid" : ""
                      } `}
                    id="nombre"
                    placeholder={t("consultationForm.placeholder.name")}
                    {...registerRegistry("name")}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="surname" className="form-label">
                    {t("consultationForm.lastname")}
                  </label>
                  <input
                    type="text"
                    className={`form-control ${registryErrors.surname ? "is-invalid" : ""
                      } `}
                    id="surname"
                    placeholder={t("consultationForm.placeholder.lastname")}
                    {...registerRegistry("surname")}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="company" className="form-label">
                    {t("consultationForm.company")}
                  </label>
                  <input
                    type="text"
                    className={`form-control ${registryErrors.entity ? "is-invalid" : ""
                      } `}
                    id="company"
                    placeholder={t("consultationForm.placeholder.company")}
                    {...registerRegistry("entity")}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="position" className="form-label">
                    {t("consultationForm.position")}
                  </label>
                  <input
                    type="text"
                    className={`form-control ${registryErrors.position ? "is-invalid" : ""
                      } `}
                    id="position"
                    placeholder={t("consultationForm.placeholder.position")}
                    {...registerRegistry("position")}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    {t("consultationForm.email")}
                  </label>
                  <input
                    type="email"
                    className={`form-control ${registryErrors.email ? "is-invalid" : ""
                      } `}
                    id="email"
                    placeholder={t("consultationForm.placeholder.email")}
                    {...registerRegistry("email")}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="phone" className="form-label">
                    {t("consultationForm.contact")}
                  </label>
                  <input
                    type="tel"
                    className={`form-control ${registryErrors.cellPhone ? "is-invalid" : ""
                      } `}
                    id="phone"
                    placeholder={t("consultationForm.placeholder.contact")}
                    {...registerRegistry("cellPhone")}
                  />
                </div>

                <div className="mb-3">
                  <input
                    type="checkbox"
                    className={`form-check-input me-2 ${registryErrors.acceptTerms ? "is-invalid " : ""
                      } `}
                    id="terms"
                    {...registerRegistry("acceptTerms")}
                  />
                  <label htmlFor="terms"> {t("consultationForm.acceptTerms")}</label>
                </div>

                <div className="d-flex flex-column">
                  {/* This text depends on the api so it will be translated, so this text has been hard coded*/}
                  <button
                    type="submit"
                    className="btn btn-success btn-lg btn-block my-2"
                  >
                    {t("consultationForm.download")}
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary btn-lg btn-block mb-2"
                    onClick={openModal}
                  >
                    {t("consultationForm.comment")}
                  </button>

                  {Object.keys(registryErrors).length > 0 && (
                    <ErrorAlert
                      message={t("errors.validationError")}
                      details={Object.keys(registryErrors).map((field) => ({
                        field,
                        message:
                          registryErrors[field as keyof IRegistryRequest]
                            ?.message || "",
                      }))}
                    />
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
        {showModal && (
          <div
            className={`custom-modal-overlay animate__animated`}
            onClick={() => closeModal("animate__backOutLeft")}
          >
            <form
              className={`custom-modal animate__animated animate__backInLeft p-3 rounded-3 ${exitAnimation || "animate__backInLeft"
                }`}
              onClick={(e) => e.stopPropagation()}
              onSubmit={handleSubmitComment(onSubmitComment)}
            >
              <div className="custom-modal-header">
                <h2> {t("consultationForm.comment")} </h2>
              </div>
              <input
                type="email"
                className={`form-control ${commentErrors.email ? "is-invalid" : ""
                  }`}
                id="email"
                placeholder={t("consultationForm.placeholder.email")}
                {...registerComment("email")}
              />
              {commentErrors.email && <p className="text-danger">error</p>}

              <div className="custom-modal-body">
                <textarea
                  className={`custom-modal-textarea w-100 rounded-3 my-3 ${commentErrors.comment ? "is-invalid" : ""
                    }`}
                  placeholder={t("consultationForm.placeholder.comment")}
                  rows={7}
                  {...registerComment("comment")}
                ></textarea>
              </div>
              <div className="custom-modal-footer">
                <button
                  className={`btn btn-secondaryanimate__animated ${exitAnimation}`}
                  onClick={() => closeModal("animate__backOutLeft")}
                >
                  {t("consultationForm.close")}
                </button>

                <button
                  type="submit"
                  className={`btn btn-primary animate__animated ${exitAnimation}`}
                >
                  {t("consultationForm.send")}
                </button>
              </div>
            </form>
          </div>
        )}
      </>
    );
};

export default PublicConsultation;

import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';

import "../styles/admin.css";

import { useAPI } from '../utils/hooks/useAPI';
import { loginSchema } from '../validations/login.validation';
import { useAuth } from '../security/authentication/AuthContext';
import { ILoginRequest } from '../common/interfaces/requests/login.request';
import { ILoginResponse } from '../common/interfaces/responses/login.response';
import ErrorAlert from '../components/ErrorAlert';
import actions from '../api/actions';

const Login: React.FC = () => {

  const { token } = useAuth();
  const location = useLocation();

  const { t } = useTranslation();

  const { apiMethods, formMethods } = useAPI<ILoginResponse, ILoginRequest>({ isPrivate: false, endpoint: actions.login, schema: loginSchema(t) });
  const { Post, isLoadingRequest, errorRequest, errorExecution, response, clearApiErrors } = apiMethods;
  const { register, handleSubmit, formState: { errors }, watch } = formMethods;
  const { login } = useAuth();

  const onSubmit: SubmitHandler<ILoginRequest> = async (data) => {
    Post(data);
  };

  useEffect(() => {
    if (response) {
      login(response.data.token);
    }
  }, [response]);

  useEffect(() => {
    const subscription = watch(() => {
      clearApiErrors();
    });
    return () => subscription.unsubscribe();
  }, [watch, clearApiErrors]);

  if (token && location.pathname === '/login') {
    return <Navigate to="/admin/dashboard" />;
  }

  return (
    <div className="container col-4 my-5">
      <h2 className="mb-4">{t('login.title')}</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            <h5>{t('login.form.email')}</h5>
          </label>
          <input
            type="email"
            className={`form-control ${errors.email ? 'is-invalid' : ''}`}
            id="email"
            {...register('email')}
            placeholder={t('login.form.emailPlaceholder')}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="password" className="form-label">
            <h5>{t('login.form.password')}</h5>
          </label>
          <input
            type="password"
            className={`form-control ${errors.password ? 'is-invalid' : ''}`}
            id="password"
            {...register('password')}
            placeholder={t('login.form.passwordPlaceholder')}
          />
        </div>

        <button type="submit" className="btn btn-primary" disabled={isLoadingRequest}>
          {isLoadingRequest ? t('login.button.loading') : t('login.button.login')}
        </button>

        {Object.keys(errors).length > 0 && (
          <ErrorAlert message={t('errors.validationError')} details={Object.keys(errors).map((field) => ({
            field,
            message: errors[field as keyof ILoginRequest]?.message || ''
          }))} />
        )}
        {
          errorRequest && (
            <ErrorAlert message={errorRequest.data.message} details={errorRequest.data.details} />
          )
        }
        {errorExecution && (
          <ErrorAlert message={t('errors.executionError')} />
        )}
      </form>
    </div>
  );
};

export default Login;
import { Routes, Route } from "react-router-dom";

import Home from '../pages/Home'
import Login from "../pages/Login";
import NotFound from "../pages/NotFound";
import CreateAdmin from "../pages/CreateUser";
import ConsultView from "../pages/ConsultView";
import AdminDashboard from "../pages/AdminDashboard";
import EditConsultation from "../pages/EditConsultation";
import PublicConsultation from "../pages/PublicConsultation";
import CreateConsultation from "../pages/CreateConsultation";
import ProtectedRoutes from "../security/authentication/ProtectedRoutes";

function AppRoutes() {
    return (
        <Routes>
            <Route element={<Home />} path="/" />
            <Route element={<Login />} path="/login" />
            <Route element={<NotFound />} path="/not-found" />
            <Route element={<PublicConsultation />} path="/:id" />
            <Route path="/admin/*" element={<ProtectedRoutes />}>
                <Route element={<AdminDashboard />} path="dashboard" />
                <Route element={<CreateAdmin />} path="create-admin" />
                <Route element={<CreateConsultation />} path="create-consultation" />
                <Route element={<EditConsultation />} path="edit-consultation/:id" />
                <Route element={<ConsultView />} path="consultations/:id" />
            </Route>

        </Routes>
    )
}

export default AppRoutes;
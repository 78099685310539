import { useState } from "react";

import { useTranslation } from "react-i18next";
import { useForm, UseFormReturn, FieldValues } from "react-hook-form";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import HttpClient from "../../api/httpClient";
import { useAuth } from "../../security/authentication/AuthContext";
import { IError } from "../../common/interfaces/hooks/error";
import IApiOptions from "../../common/interfaces/hooks/apiOptions";
import IHttpResponse from "../../common/interfaces/hooks/httpResponse";
import { THttpMethod } from "../../common/interfaces/hooks/httpMethods";

const BASE_URL = import.meta.env.VITE_BACKEND_URL;

interface UseAPIProps<TRequest extends FieldValues> extends IApiOptions {
  schema?: yup.ObjectSchema<TRequest>;
}

interface UseAPIResponse<TResponse, TRequest extends FieldValues> {
  /**
   * Methods and properties for handling form state and validation.
   */
  formMethods: UseFormReturn<TRequest>;
  /**
   * Methods and properties for making API requests.
   */
  apiMethods: {
    /**
     * Makes a GET request to the specified endpoint.
     *
     */
    Get: (endpoint: string) => void;
    /**
     * Makes a POST request to the specified endpoint with the given body.
     *
     * @param {TRequest} body - The request body.
     */
    Post: (body: TRequest) => void;
    /**
     * Makes a DELETE request to the specified endpoint with the given body.
     *
     * @param {TRequest} body - The request body.
     */
    Delete: (body: TRequest) => void;
    /**
     * Makes a PUT request to the specified endpoint with the given body.
     *
     * @param {TRequest} body - The request body.
     */
    Put: (body: TRequest) => void;
    /**
     * Indicates if a request is currently loading.
     */
    isLoadingRequest: boolean;
    /**
     * The error response from the request, if any.
     */
    errorRequest: IHttpResponse<IError> | null;
    /**
     * The error that occurred during the request execution, if any.
     */
    errorExecution: Error | null;
    /**
     * Clears response and execution (API) errors states.
     */
    clearApiErrors: () => void;
    /**
     * The response from the request, if any.
     */
    response: IHttpResponse<TResponse> | null;
  };
}

/**
 * Custom hook to handle API requests and form management.
 *
 * @template TResponse - The type of the response data.
 * @template TRequest - The type of the request data.
 *
 * @param {UseAPIProps<TRequest>} props - The properties for the hook.
 * @param {boolean} props.isPrivate - Indicates if the API request requires authentication.
 * @param {yup.ObjectSchema<TRequest>} props.schema - The validation schema for the form.
 *
 * @returns {UseAPIResponse<TResponse, TRequest>} - The form methods and API methods.
 */
export const useAPI = <TResponse, TRequest extends FieldValues = any>({
  endpoint,
  isPrivate = false,
  schema,
}: UseAPIProps<TRequest>): UseAPIResponse<TResponse, TRequest> => {
  const { i18n } = useTranslation();
  const { token, logout } = useAuth();

  const headers = {
    lng: i18n.language,
    ...(isPrivate && token && { Authorization: `Bearer ${token}` }),
  };

  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  const [errorRequest, setErrorRequest] =
    useState<IHttpResponse<IError> | null>(null);
  const [errorExecution, setErrorExecution] = useState<Error | null>(null);
  const [response, setResponse] = useState<IHttpResponse<TResponse> | null>(
    null
  );

  const formMethods = useForm<TRequest>({
    resolver: yupResolver(schema as yup.ObjectSchema<any>),
  });

  /**
   * Makes an API request.
   *
   * @param {THttpMethod} method - The HTTP method to use for the request.
   * @param {any} [body] - The request body.
   */
  const request = async (method: THttpMethod, body?: any) => {
    setIsLoadingRequest(true);
    const client = new HttpClient(BASE_URL + endpoint, headers, body);
    client[method]()
      .then((responseHttp) => {
        setResponse(responseHttp as IHttpResponse<TResponse>);
      })
      .catch((error) => {
        if (error.response && error.response.status) {
          if (error.response.status === 401) {
            logout();
          }
          setErrorRequest(error.response);
        } else {
          setErrorExecution(error);
        }
      })
      .finally(() => {
        setIsLoadingRequest(false);
      });
  };

  /**
   * Makes a GET request to the specified endpoint.
   *
   */
  const Get = () => request("get");

  /**
   * Makes a POST request to the specified endpoint with the given body.
   *
   * @param {TRequest} body - The request body.
   */
  const Post = (body: TRequest) => request("post", body);

  /**
   * Makes a DELETE request to the specified endpoint with the given body.
   *
   * @param {TRequest} body - The request body.
   */
  const Delete = (body: TRequest) => request("delete", body);

  /**
   * Makes a PUT request to the specified endpoint with the given body.
   *
   * @param {TRequest} body - The request body.
   */
  const Put = (body: TRequest) => request("put", body);

  /**
   * Clears the errors state.
   */
  const clearApiErrors = () => {
    setErrorRequest(null);
    setErrorExecution(null);
  };

  return {
    formMethods,
    apiMethods: {
      Get,
      Post,
      Delete,
      Put,
      isLoadingRequest,
      errorRequest,
      errorExecution,
      clearApiErrors,
      response,
    },
  };
};

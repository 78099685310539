import { useEffect, useState } from "react";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SubmitHandler, Controller } from "react-hook-form";

import "react-quill/dist/quill.snow.css";
import "../styles/admin.css";

import ReactQuill from "react-quill";

import { useAPI } from "../utils/hooks/useAPI";
import { ICreateConsultationRequest } from "../common/interfaces/requests/create-consultation.request";
import { createConsultationSchema } from "../validations/create-consultation.validation";
import { IPreSignedPostData } from "../common/interfaces/responses/file";

import ErrorAlert from "../components/ErrorAlert";
import actions from "../api/actions";

const CreateConsultation = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  ///////////////////////          Hooks            //////////////////////////////
  const { apiMethods: apiMethodsConsultation, formMethods } = useAPI<
    ICreateConsultationRequest,
    ICreateConsultationRequest
  >({
    isPrivate: true,
    endpoint: actions.createConsultation,
    schema: createConsultationSchema(t),
  });
  const { apiMethods: apiMethodsPreSignedPostSpanish } =
    useAPI<IPreSignedPostData>({
      isPrivate: true,
      endpoint: actions.generateUrlToUploadFile,
    });
  const { apiMethods: apiMethodsPreSignedPostEnglish } =
    useAPI<IPreSignedPostData>({
      isPrivate: true,
      endpoint: actions.generateUrlToUploadFile,
    });
  ////////////////////////         Destructuring        //////////////////////////
  const { Post, isLoadingRequest, errorRequest, errorExecution, response } =
    apiMethodsConsultation;
  const {
    Post: PostPreSignedPostSpanish,
    isLoadingRequest: isLoadingPreSignedPostSpanish,
    errorRequest: errorRequestPreSignedPostSpanish,
    errorExecution: errorExecutionPreSignedPostSpanish,
    response: responsePreSignedPostSpanish,
  } = apiMethodsPreSignedPostSpanish;
  const {
    Post: PostPreSignedPostEnglish,
    isLoadingRequest: isLoadingPreSignedPostEnglish,
    errorRequest: errorRequestPreSignedPostEnglish,
    errorExecution: errorExecutionPreSignedPostEnglish,
    response: responsePreSignedPostEnglish,
  } = apiMethodsPreSignedPostEnglish;
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    clearErrors,
    setError,
  } = formMethods;

  const [documentSpanish, setDocumentSpanish] = useState<File | null>(null);
  const [documentEnglish, setDocumentEnglish] = useState<File | null>(null);

  const uploadFile = async (
    file: File,
    preSignedPostData: IPreSignedPostData,
    fieldName: "documentNameSpanish" | "documentNameEnglish"
  ) => {
    const formData = new FormData();
    Object.entries(preSignedPostData.preSignedPost.fields).forEach(
      ([key, value]) => {
        formData.append(key, value);
      }
    );
    formData.append("file", file);

    try {
      await axios.post(preSignedPostData.preSignedPost.url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      setError(fieldName, {
        type: "manual",
        message: t("errors.uploadError"),
      });
    }
  };

  const handleUploadSpanish = async () => {
    if (documentSpanish && responsePreSignedPostSpanish) {
      uploadFile(
        documentSpanish,
        responsePreSignedPostSpanish.data,
        "documentNameSpanish"
      );
    }
  };

  const handleUploadEnglish = async () => {
    if (documentEnglish && responsePreSignedPostEnglish) {
      uploadFile(
        documentEnglish,
        responsePreSignedPostEnglish.data,
        "documentNameEnglish"
      );
    }
  };

  const onSubmit: SubmitHandler<ICreateConsultationRequest> = async (data) => {
    if (!responsePreSignedPostSpanish || !responsePreSignedPostEnglish) {
      return;
    }
    const requestBody = {
      ...data,
      documentNameSpanish: `${responsePreSignedPostSpanish?.data.time}_${responsePreSignedPostSpanish?.data.name}`,
      documentNameEnglish: `${responsePreSignedPostEnglish?.data.time}_${responsePreSignedPostEnglish?.data.name}`,
    };
    Post(requestBody);
  };

  useEffect(() => {
    if (documentSpanish) {
      PostPreSignedPostSpanish({
        fileName: documentSpanish.name,
        module: "public-consultations",
      });
    }
  }, [documentSpanish]);

  useEffect(() => {
    if (responsePreSignedPostSpanish) {
      handleUploadSpanish();
    }
  }, [responsePreSignedPostSpanish]);

  useEffect(() => {
    if (documentEnglish) {
      PostPreSignedPostEnglish({
        fileName: documentEnglish.name,
        module: "public-consultations",
      });
    }
  }, [documentEnglish]);

  useEffect(() => {
    if (responsePreSignedPostEnglish) {
      handleUploadEnglish();
    }
  }, [responsePreSignedPostEnglish]);

  useEffect(() => {
    if (response && response.status === 200) {
      navigate("/admin/dashboard");
    }
  }, [response]);

  return (
    <div className="container my-5 px-1">
      <h2 className="mb-4">{t("createConsultation.title")}</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3">
          <label htmlFor="title" className="form-label">
            <h5>{t("createConsultation.form.name")} *</h5>
          </label>
          <input
            type="text"
            className={`form-control ${errors.name ? "is-invalid" : ""}`}
            id="name"
            {...register("name")}
            placeholder={t("createConsultation.form.placeholderName")}
          />
        </div>
        <div className="row mb-3">
          <div className="col-6">
            <label htmlFor="descriptionEs" className="form-label">
              <h5>{t("createConsultation.form.description.spanish")} *</h5>
            </label>
            <Controller
              name="descriptionSpanish"
              control={control}
              render={({ field }) => (
                <div
                  className={`${
                    errors.descriptionSpanish ? "border border-danger" : ""
                  }`}
                >
                  <ReactQuill
                    {...field}
                    modules={{
                      toolbar: [
                        [{ header: "1" }, { header: "2" }, { font: [] }],
                        [{ size: [] }],
                        [{ align: [] }],
                        ["bold", "italic", "underline", "strike", "blockquote"],
                        [{ list: "ordered" }, { list: "bullet" }],
                        ["link"],
                      ],
                    }}
                    id="descriptionEs"
                    theme="snow"
                    placeholder={t(
                      "createConsultation.form.description.placeholderDescriptionSpanish"
                    )}
                  />
                </div>
              )}
            />
          </div>
          <div className="col-6">
            <label htmlFor="descriptionEn" className="form-label">
              <h5>{t("createConsultation.form.description.spanish")} *</h5>
            </label>
            <Controller
              name="descriptionEnglish"
              control={control}
              render={({ field }) => (
                <div
                  className={`${
                    errors.descriptionEnglish ? "border border-danger" : ""
                  }`}
                >
                  <ReactQuill
                    {...field}
                    modules={{
                      toolbar: [
                        [{ header: "1" }, { header: "2" }, { font: [] }],
                        [{ size: [] }],
                        [{ align: [] }],
                        ["bold", "italic", "underline", "strike", "blockquote"],
                        [{ list: "ordered" }, { list: "bullet" }],
                        ["link"],
                      ],
                    }}
                    id="descriptionEn"
                    theme="snow"
                    placeholder={t(
                      "createConsultation.form.description.placeholderDescriptionEnglish"
                    )}
                  />
                </div>
              )}
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-6">
            <label htmlFor="startDate" className="form-label">
              <h5>{t("createConsultation.form.startDate")} *</h5>
            </label>
            <input
              type="date"
              className={`form-control ${errors.startDate ? "is-invalid" : ""}`}
              id="startDate"
              {...register("startDate")}
            />
          </div>
          <div className="col-6">
            <label htmlFor="endDate" className="form-label">
              <h5>{t("createConsultation.form.endDate")} *</h5>
            </label>
            <input
              type="date"
              className={`form-control ${errors.endDate ? "is-invalid" : ""}`}
              id="endDate"
              {...register("endDate")}
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-6">
            <label htmlFor="documentSpanish" className="form-label">
              <h5>{t("createConsultation.form.documentNameSpanish")} *</h5>
            </label>
            <Controller
              name="documentNameSpanish"
              control={control}
              render={() => (
                <input
                  type="file"
                  className={`form-control ${
                    errors.documentNameSpanish ? "is-invalid" : ""
                  }`}
                  id="documentSpanish"
                  onChange={(e) => {
                    const file = e.target.files?.[0];
                    if (file) {
                      clearErrors("documentNameSpanish");
                      setDocumentSpanish(file);
                      setValue("documentNameSpanish", file.name);
                    }
                  }}
                />
              )}
            />
          </div>
          <div className="col-6">
            <label htmlFor="documentEnglish" className="form-label">
              <h5>{t("createConsultation.form.documentNameEnglish")} *</h5>
            </label>
            <Controller
              name="documentNameEnglish"
              control={control}
              render={() => (
                <input
                  type="file"
                  className={`form-control ${
                    errors.documentNameEnglish ? "is-invalid" : ""
                  }`}
                  id="documentEnglish"
                  onChange={(e) => {
                    const file = e.target.files?.[0];
                    if (file) {
                      clearErrors("documentNameEnglish");
                      setDocumentEnglish(file);
                      setValue("documentNameEnglish", file.name);
                    }
                  }}
                />
              )}
            />
          </div>
        </div>

        <div className="mb-3">
          <label htmlFor="document" className="form-label me-3">
            <h5>{t("createConsultation.form.active")}</h5>
          </label>
          <input
            type="checkbox"
            className={`form-check-input`}
            id="active"
            {...register("active")}
            defaultChecked={true}
          />
        </div>

        <button
          type="submit"
          className={`btn ${
            isLoadingRequest ||
            isLoadingPreSignedPostEnglish ||
            isLoadingPreSignedPostSpanish
              ? "btn-secondary"
              : "btn-primary"
          }`}
          disabled={
            isLoadingRequest ||
            isLoadingPreSignedPostEnglish ||
            isLoadingPreSignedPostSpanish
          }
        >
          {t("createConsultation.button.create")}
        </button>
      </form>
      {Object.keys(errors).length > 0 && (
        <ErrorAlert
          message={t("errors.validationError")}
          details={Object.keys(errors).map((field) => ({
            field,
            message:
              errors[field as keyof ICreateConsultationRequest]?.message || "",
          }))}
        />
      )}
      {errorRequest && (
        <ErrorAlert
          message={errorRequest.data.message}
          details={errorRequest.data.details}
        />
      )}
      {errorExecution && <ErrorAlert message={errorExecution.message} />}
      {errorRequestPreSignedPostSpanish && (
        <ErrorAlert
          message={errorRequestPreSignedPostSpanish.data.message}
          details={errorRequestPreSignedPostSpanish.data.details}
        />
      )}
      {errorExecutionPreSignedPostSpanish && (
        <ErrorAlert message={errorExecutionPreSignedPostSpanish.message} />
      )}
      {errorRequestPreSignedPostEnglish && (
        <ErrorAlert
          message={errorRequestPreSignedPostEnglish.data.message}
          details={errorRequestPreSignedPostEnglish.data.details}
        />
      )}
      {errorExecutionPreSignedPostEnglish && (
        <ErrorAlert message={errorExecutionPreSignedPostEnglish.message} />
      )}
    </div>
  );
};

export default CreateConsultation;

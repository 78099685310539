import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { jwtDecode } from 'jwt-decode'; 

interface ProtectedRoutesProps {
  children?: JSX.Element;
}

const ProtectedRoutes: React.FC<ProtectedRoutesProps> = ({ children }) => {
  const { token, logout } = useAuth();
  const location = useLocation();

  useEffect(() => {
    if (token) {
      const decodedToken: any = jwtDecode(token);
      if (decodedToken.exp * 1000 < Date.now()) {
        logout();
      }
    }
  }, [token, logout]);

  if (token && location.pathname === '/login') {
    return <Navigate to="/admin/dashboard" />;
  }

  if (!token && location.pathname !== '/login') {
    return <Navigate to="/login" />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoutes;
import * as yup from 'yup';

export const registrySchema = (t: (key: string) => string) => yup.object().shape({
    email: yup.string().email(t('consultationForm.errors.emailInvalid')).required(t('consultationForm.errors.emailRequired')),
    name: yup.string().required(t('consultationForm.errors.nameRequired')),
    surname: yup.string().required(t('consultationForm.errors.surnameRequired')),
    entity: yup.string().required(t('consultationForm.errors.entityRequired')),
    position: yup.string().required(t('consultationForm.errors.positionRequired')),
    cellPhone: yup.string().required(t('consultationForm.errors.phoneRequired')),
    acceptTerms: yup.boolean().oneOf([true],t('consultationForm.errors.acceptTermsRequired')).required(t('consultationForm.errors.acceptTermsRequired'))
});
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAPI } from '../utils/hooks/useAPI'; // Asegúrate de importar tu hook
import "../styles/consultation.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { IConsultation } from "../common/interfaces/responses/consultation";

const AdminDashboard = () => {

  const navigate = useNavigate();
  const {
    apiMethods: { Get: getConsultations, response },
  } = useAPI<IConsultation[]>({
    endpoint: "/consultations",
    isPrivate: false,
  });

  useEffect(() => {
    getConsultations("");
  }, []);

  return (
    <div className="container my-5">
      <h2 className="mb-4 text-center">Vista General de Tablas de Administración</h2>
      <div className="d-flex justify-content-around">
        <div className="mb-4">
          <button
            className="btn btn-success"
            onClick={() => navigate("/admin/create-admin")}
          >
            Crear Nuevo Administrador
          </button>
        </div>
        <div className="mb-4">
          <button
            className="btn btn-success"
            onClick={() => navigate("/admin/create-consultation")}
          >
            Crear Nueva Consulta
          </button>
        </div>
      </div>
      <div
        className="table-responsive my-5 "
        style={{ maxHeight: "550px", overflowY: "auto" }}
      >
        <h3>Consultas Públicas</h3>
        <table className="table table-bordered clickable table-hover">
          <thead>
            <tr>
              <th>ID</th>
              <th>Título</th>
              <th>Fecha de Inicio</th>
              <th>Fecha de Finalización</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {response?.data.map((consultation) => (
              <tr
                key={consultation.id}
                onClick={() => navigate(`/${consultation.id}`)}
              >
                <td>{consultation.id}</td>
                <td>{consultation.name}</td>
                <td>{consultation.startDate}</td>
                <td>{consultation.endDate}</td>
                <td className="d-flex justify-content-center">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      // handleComments(consultation.id);
                      navigate(`/admin/consultations/${consultation.id}`);
                    }}
                    className="btn btn-outline-secondary btn-sm me-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-eye"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                      <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                    </svg>
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/admin/edit-consultation/${consultation.id}`);
                    }}
                    className="btn btn-outline-secondary btn-sm me-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-eye"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z" /></svg>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};



export default AdminDashboard;

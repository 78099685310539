import "../styles/Navbar.css";
import logo from "../assets/logo-footer.png";
import { useTranslation } from "react-i18next";
import { useAuth } from '../security/authentication/AuthContext';
import { useNavigate } from "react-router-dom";

const Header = () => {
  const { i18n, t } = useTranslation();
  const { token, logout } = useAuth();
  const navigate=useNavigate();

  function selectLanguage(lng: string): void {
    i18n.changeLanguage(lng);
  }

  return (
    <div className="container">
      <div className="row header justify-content-arround no-gutters">
        <div className="col-8 d-flex">
          <a href="https://www.cercarbono.com/es/" target="_blank" rel="noopener noreferrer">
            <img src={logo} alt="cercarbono" className="ms-5 mt-4" />
          </a>
        </div>
        <div className="col d-flex justify-content-end align-items-center">
          <ul className="nav align-items-center">
            {token && (
              <li className="nav-item me-5" id="logout">
                <button className="btn btn-secondary" onClick={logout}>
                  {t('navbar.logout')}
                </button>
              </li>
            )}
            <li>
              <button className="btn btn-primary me-3" onClick={()=>navigate(token ?'/admin/dashboard' : '/')}> volver</button>
            </li>
            <li className="flag-containe nav-item mx-2" id="en">
              <div className="fi fi-gb flag-size" onClick={() => selectLanguage('en')}></div>
            </li>
            <li className="flag-containe nav-item me-5" id="es">
              <div className="fi fi-es flag-size" onClick={() => selectLanguage('es')}></div>
            </li>
            
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
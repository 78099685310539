import * as yup from "yup";

export const updateConsultationSchema = (t: (key: string) => string) =>
  yup.object().shape({
    name: yup.string().required(t("editConsultation.validation.name")),
    descriptionEnglish: yup
      .string()
      .required(t("editConsultation.validation.descriptionEnglish")),
    descriptionSpanish: yup
      .string()
      .required(t("editConsultation.validation.descriptionSpanish")),
    documentNameSpanish: yup
      .string()
      .required(t("editConsultation.validation.documentNameSpanish")),
    documentNameEnglish: yup
      .string()
      .required(t("editConsultation.validation.documentNameEnglish")),
    active: yup.boolean().required(t("editConsultation.validation.active")),
    startDate: yup
      .string()
      .required(t("editConsultation.validation.startDate")),
    endDate: yup
      .string()
      .required(t("editConsultation.validation.endDate"))
      .test(
        "is-greater",
        t("editConsultation.validation.endDateGreaterThanStartDate"),
        function (value) {
          const { startDate } = this.parent;
          return !startDate || !value || new Date(value) >= new Date(startDate);
        }
      ),
  });
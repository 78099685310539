import Header from "./Header";

interface LayoutProps {
  children: React.ReactNode;
}

const AppLayout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <Header />
      <main className="px-5 py-3"><>{children}</></main>
    </>
  );
};


export default AppLayout;

import { useForm } from "react-hook-form";
import "../styles/admin.css"; // Asegúrate de que esta ruta sea correcta
import { useNavigate } from "react-router-dom";
import { useAPI } from "../utils/hooks/useAPI";




const CreateAdmin = () => {

  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();

  // const handleSubmit = (e: React.FormEvent) => {
  //   e.preventDefault();

  //   const adminData = {
  //     name,
  //     email,
  //     password,
  //   };

  //   fetch(`${backendUrl}/user/register`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(adminData),
  //   })
  //     .then((response) => {
  //       if (!response.ok) {
  //         return response.json().then((errorData) => {
  //           throw new Error(errorData.message || "Error en la creación del administrador");
  //         });
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       console.log("Administrador creado:", data);
  //     })
  //     .catch((error) => {
  //       console.error("Error en la solicitud:", error);
  //     });
  // };
  const {
    apiMethods: { Post, response },
  } = useAPI({
    endpoint: "/user/register",
    isPrivate: true,
  });

  const onSubmit = handleSubmit((data) => {
    Post(data);
    console.log(data)
    if (response && response.status === 200) {
      navigate('/admin/dashboard');
    }
  })

  return (
    <div className="container col-6 my-5">
      <h2 className="mb-4">Crear Nuevo Administrador</h2>
      <form onSubmit={onSubmit}>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            <h5>Nombre</h5>
          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            placeholder="Ingrese el nombre"
            {...register("name")}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            <h5>Correo Electrónico</h5>
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            placeholder="Ingrese el correo electrónico"
            {...register("email")}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="password" className="form-label">
            <h5>Contraseña</h5>
          </label>
          <input
            type="password"
            className="form-control"
            id="password"
            placeholder="Ingrese la contraseña"
            {...register("password")}
          />
        </div>

        <button type="submit" className="btn btn-primary" >
          Crear Administrador
        </button>
      </form>
    </div>
  );
};

export default CreateAdmin;

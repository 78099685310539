import { BrowserRouter } from "react-router-dom";

import AppRoutes from "./routes/AppRoutes";
import AppLayout from "./components/AppLayout";

import { AuthProvider } from "./security/authentication/AuthContext";

import './styles/App.css'
import "/node_modules/flag-icons/css/flag-icons.min.css";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
            <AppLayout>
              <AppRoutes/>
            </AppLayout>
      </AuthProvider>
    </BrowserRouter>
  )
}

export default App

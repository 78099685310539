// import IBody from "../common/interfaces/api/body";

export default abstract class HttpRequest {

  protected url: string;
  protected headers?: { [key: string]: string };
  protected body?: any;

  constructor(url: string, headers?: { [key: string]: string }, body?: any) {
    this.url = url;
    this.headers = headers;
    this.body = body;
  }

  abstract get(): Promise<unknown>;

  abstract post(): Promise<unknown>;

  abstract put(): Promise<unknown>;

  abstract patch(): Promise<unknown>;

  abstract delete(): Promise<unknown>;

}
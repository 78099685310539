import axios from "axios";

import HttpRequest from "./httpRequest";

export default class HttpClient extends HttpRequest {

  async get() {
    return axios.get(this.url, {headers: this.headers});
  }

  async post() {
    return axios.post(this.url, this.body, {headers: this.headers});
  }

  async put() {
    return axios.put(this.url, this.body, {headers: this.headers});
  }

  async patch() {
    return axios.patch(this.url, this.body, {headers: this.headers});
  }

  async delete() {
    return axios.delete(this.url, {headers: this.headers});
  }

}
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAPI } from "../utils/hooks/useAPI";
import ScreenLoading from "../components/ScreenLoading";
import { IConsultation } from "../common/interfaces/responses/consultation";

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    apiMethods: { Get, isLoadingRequest, response },
  } = useAPI<IConsultation[]>({
    endpoint: "/consultations",
    isPrivate: false,
  });

  useEffect(() => {
    Get("");  //IDK y have to put quotes
  }, []);

  if (isLoadingRequest) {
    return <ScreenLoading />
  } else

    return (
      <>

        <h1> {t("home.title")} </h1>

        <div
          className="table-responsive my-5 "
          style={{ maxHeight: "550px", overflowY: "auto" }}
        >
          <table className="table table-bordered clickable table-hover">
            <thead>
              <tr>
                <th>{t("home.tableColumnNames.id")}</th>
                <th>{t("home.tableColumnNames.name")}</th>
                <th>{t("home.tableColumnNames.initialDate")}</th>
                <th>{t("home.tableColumnNames.finalDate")}</th>
              </tr>
            </thead>
            <tbody>
              {response &&
                [...response.data]
                  .sort((a, b) => Number(b.id) - Number(a.id)) // Sort by id in descending order
                  .map((consultation) => (
                    <tr
                      key={consultation.id}
                      onClick={() => navigate(`/${consultation.id}`)}
                    >
                      <td>{consultation.id}</td>
                      <td>{consultation.name}</td>
                      <td>{consultation.startDate}</td>
                      <td>{consultation.endDate}</td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </>
    );
};

export default Home;

import i18n from "i18next";
import Backend from "i18next-http-backend";
// import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(Backend)
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: ["en", "es"],
    // ns: ["translation"],
    // defaultNS: "translation",
    interpolation: {
      escapeValue: false
    },
    // backend: {
    //   loadPath: (lng, ns) => `src/public/locales/languages/${lng}/${ns}.json`,
    // },
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json`,
    },
    // debug: true,
  });

export default i18n;

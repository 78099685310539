import React from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

import { ErrorAlertProps } from '../common/interfaces/components/errorAlert.interface';

const ErrorAlert: React.FC<ErrorAlertProps> = ({ message, details }) => {
    return (
        <div className="alert alert-danger mt-3">
            <p className="flex items-center">
                <ExclamationCircleIcon className="me-2" style={{ width: '1.5rem' }} />
                {message}
            </p>
            {details && details.length > 0 && (
                <ul className='ms-3'>
                    {details.map((detail, index) => (
                        <li key={index}>{detail.message}</li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default ErrorAlert;